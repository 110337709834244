import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Media,
  Image,
  Table,
  Form,
  FormGroup,
} from "react-bootstrap";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { t, withTranslation } from "react-multi-lang";
import { useParams } from "react-router";
import {
  fetchModelPostsStart,
  fetchMoreModelPostsStart,
  fetchModelRatingStart,
  fetchModelSubStart,
  fetchModelSuggestionStart,
  fetchSingleModelStart,
} from "../../store/actions/ModelAction";
import { connect } from "react-redux";
import ModelProfileLoader from "../Handlers/Loaders/ModelProfileLoader";
import {
  followUserStart,
  unFollowUserStart,
} from "../../store/actions/FollowAction";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ImageLoader from "../Helper/ImageLoader";
import AllReviewModal from "../ModelProfile/AllReviewModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router";
import { fetchCardDetailsStart } from "../../store/actions/CardAction";

const SingleModelNew = (props) => {
  useEffect(() => {
    if (localStorage.getItem("user_unique_id")) {
      props.dispatch(fetchCardDetailsStart());
    }
  }, []);

  const addcard = (event) => {
    const notificationMessage = getErrorNotificationMessage(
      t("please_add_card_try_again")
    );
    window.location.assign("/cards");
    props.dispatch(createNotification(notificationMessage));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>",
    ],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 1.5,
      },
      1000: {
        items: 1.5,
      },
    },
  };

  const params = useParams();
  const history = useHistory();
  const reviewSec = useRef();
  const [selectedSub, setSelectedSub] = useState(null);
  const [allReviewModal, setAllReviewModal] = useState(false);

  const showRating = (event) => {
    event.preventDefault();

    // if (props.modelRating.loading)
    props.dispatch(
      fetchModelRatingStart({
        model_username: props.match.params.username,
      })
    );

    setAllReviewModal(true);
  };

  const handleCloseAllReviewModal = () => {
    setAllReviewModal(false);
  };

  useEffect(() => {
    props.dispatch(
      fetchSingleModelStart({
        username: params.username,
      })
    );
    props.dispatch(
      fetchModelPostsStart({
        model_username: params.username,
        skip: 0,
        take: 3,
      })
    );

    props.dispatch(fetchModelSubStart({ model_username: params.username }));

    props.dispatch(
      fetchModelSuggestionStart({
        model_username: params.username,
      })
    );
  }, [params.username]);

  const [skipFirst, setSkipFirst] = useState(true);

  useEffect(() => {
    if (!skipFirst &&
      !props.modelSub.loading &&
      Object.keys(props.modelSub.data).length > 0 &&
      props.modelSub.data.model_subscriptions.length > 0
    ) {
      const defaultSubscription = props.modelSub.data.model_subscriptions.filter((sub => sub.is_default == 1));
      if (defaultSubscription.length > 0) {
        setSelectedSub(defaultSubscription[0]);
      } else {
        setSelectedSub(props.modelSub.data.model_subscriptions[0]);
      }
    }
    setSkipFirst(false);
  }, [props.modelSub]);

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Profile Link Copied!!"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const joinFanClub = () => {
    if (localStorage.getItem("user_unique_id")) {
      props.dispatch(
        followUserStart({ user_id: props.singleModel.data.user.user_id })
      );
    } else {
      history.push("/login");
    }
  };

  const exitFanClub = () => {
    props.dispatch(
      unFollowUserStart({ user_id: props.singleModel.data.user.user_id })
    );
  };

  return (
    <>
      {props.singleModel.loading ? (
        <ModelProfileLoader />
      ) : (
        <>
          <div className="single-model-new-sec">
            <Container>
              <div className="single-model-new-header-sec">
                <Breadcrumb>
                  <Breadcrumb.Item href="/">{t("home")}</Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/browse/${props.singleModel.data.categories.category
                      ? props.singleModel.data.categories.category
                        .category_unique_id
                      : ""
                      }`}
                  >
                    {props.singleModel.data.user.category_name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {props.singleModel.data.user.subcategory_name}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="btn-action-sec">
                  <Link to={localStorage.getItem("userId") ? `/event-request/${params.username}` : "/login"}>
                    <Button className="btn-videocall">{t("video_call")}</Button>
                  </Link>
                  {props.singleModel.data.user.user_id !=
                    localStorage.getItem("userId") && (
                      <Button
                        className="btn-join"
                        onClick={
                          props.singleModel.data.follow_status
                            ? exitFanClub
                            : joinFanClub
                        }
                      >
                        {props.singleModel.data.follow_status
                          ? t("unfollow")
                          : t("follow")}
                      </Button>
                    )}
                  <Button className="btn-copy">
                    <CopyToClipboard
                      text={window.location.href}
                      onCopy={onCopy}
                    >
                      <Image
                        src={window.location.origin + "/assets/images/copy.svg"}
                        alt="user-image"
                        className="icons-img"
                      />
                    </CopyToClipboard>
                  </Button>
                </div>
              </div>
              <div className="single-model-new-body-sec">
                <Row>
                  <Col md={6}>
                    {props.modelPost.loading ? (
                      <Skeleton height={480} />
                    ) : (
                      <div className="single-model-new-video-slider-sec">
                        {props.modelPost.data.posts &&
                          props.modelPost.data.posts.length > 0 ? (
                          <OwlCarousel
                            className="owl-theme"
                            loop
                            {...options}
                            nav
                          >
                            {props.modelPost.data.posts.map((post, i) => (
                              <Link
                                className="single-model-new-video-card"
                                key={i}
                                to={`/post/` + post.post_unique_id}
                              >
                                <div className="single-model-new-video-img-sec">
                                  <Image
                                    src={
                                      post.file_type == "video"
                                        ? post.preview_image
                                        : post.file
                                    }
                                    alt="user-image"
                                    className="single-model-new-video-img"
                                  />
                                </div>
                              </Link>
                            ))}
                            <Link
                              className="single-model-new-video-card"
                              to={`/model-post-view/${params.username}`}
                            >
                              <div className="model-post-load-more-sec">
                                <div>Load More</div>
                                {/* <Image
                                src={window.location.origin +
                                  "/assets/images/load-more-img.png"}
                                alt="user-image"
                                className="single-model-new-video-img"
                              /> */}
                              </div>
                            </Link>
                          </OwlCarousel>
                        ) : (
                          <OwlCarousel className="owl-theme" {...options}>
                            <div className="single-model-new-video-card">
                              <div className="single-model-new-video-img-sec">
                                <Image
                                  src={props.singleModel.data.user.picture}
                                  alt="user-image"
                                  className="single-model-new-video-img"
                                />
                              </div>
                            </div>
                          </OwlCarousel>
                        )}
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className="single-model-new-info-sec">
                      <div className="single-model-new-info-header">
                        <div className="rating-star-model-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="#FFF400"
                            stroke="#FFF400"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            data-testid="icon-star"
                          >
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                          </svg>
                          <span>
                            {props.singleModel.data.avg_model_ratings.toFixed(
                              1
                            )}
                          </span>
                        </div>
                        <div className="all-review-count-sec">
                          <Link
                            to="#"
                            onClick={(e) =>
                              props.singleModel.data.user.total_ratings > 0
                                ? window.scrollTo({
                                  top: reviewSec.current.offsetTop - 400,
                                  behavior: "smooth",
                                })
                                : e.preventDefault()
                            }
                          >
                            {props.singleModel.data.user.total_ratings}{" "}
                            {t("reviews")}
                          </Link>
                        </div>
                        <span className="dot-theme">•</span>
                        <div className="follower-count">
                          {props.singleModel.data.total_followers}
                        </div>
                        <div className="follower-text">{t("followers")}</div>
                      </div>
                      <div className="single-model-bio-sec">
                        <div className="single-model-bio-info">
                          <h2>{props.singleModel.data.user.name}</h2>
                          <p>{props.singleModel.data.user.subcategory_name}</p>
                        </div>
                        <div className="single-model-user-img-sec">
                          <ImageLoader
                            image={props.singleModel.data.user.picture}
                            className="single-model-profile-img"
                          />
                        </div>
                      </div>
                      <div className="delivery-available-status">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/delivery-status.svg"
                          }
                          alt="user-image"
                          className="delivery-available-status-icon"
                        />
                        <p>{t("delivery_available")}</p>
                      </div>
                      <div className="single-profile-new-model-details">
                        {props.singleModel.data.user.about}
                      </div>
                      {props.modelSub.loading ? (
                        "Loading"
                      ) : props.modelSub.data.model_subscriptions &&
                        props.modelSub.data.model_subscriptions.length > 0 ? (
                        <div className="single-profile-new-model-radio-group">
                          <h4>{t("choose_subscription")}</h4>
                          <Form className="single-model-choose-option-sec">
                            {props.modelSub.data.model_subscriptions.map(
                              (subscription, index) => (
                                <Form.Group
                                  onClick={() => setSelectedSub(subscription)}
                                >
                                  <label
                                    className="card-choose-option"
                                    key={index}
                                  >
                                    <input
                                      name="plan"
                                      className="radio"
                                      type="radio"
                                      checked={selectedSub === subscription}
                                      value={subscription}
                                    />
                                    <div className="card-option-box">
                                      <div className="card-option-left-sec">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/images/users-white.svg"
                                          }
                                          alt="user-image"
                                          className="users-icon"
                                        />
                                        <div className="card-option-left-info">
                                          <h5>{subscription.title}</h5>
                                          <p>{subscription.plan_formatted}</p>
                                          <p>{subscription.description}</p>
                                        </div>
                                      </div>
                                      <div className="card-option-right-sec">
                                        <h6>{subscription.amount_formatted}</h6>
                                      </div>
                                    </div>
                                  </label>
                                </Form.Group>
                              )
                            )}
                            {localStorage.getItem("user_unique_id") ?
                              <>
                                {props.singleModel.data.user.user_id !=
                                  localStorage.getItem("userId") ? (
                                  <FormGroup>
                                    {localStorage.getItem(
                                      "user_unique_id"
                                    ) ? (
                                      selectedSub !== null ? (
                                        <Link
                                          to={{
                                            pathname: `/request-booking/${props.singleModel.data.user.username}/${selectedSub.user_subscription_id}`,
                                            model:
                                              props.singleModel.data.user,
                                            subscription: selectedSub,
                                          }}
                                          className="btn-edit-profile btn btn-primary"
                                        >
                                          {t("book_now")}{" "}
                                          {selectedSub.amount_formatted}
                                        </Link>
                                      ) : (
                                        <Button
                                          className="btn-edit-profile btn btn-primary"
                                          disabled={true}
                                        >
                                          {t("book_now")}
                                        </Button>
                                      )
                                    ) : (
                                      <Link
                                        to="/login"
                                        className="btn-edit-profile btn btn-primary"
                                      >
                                        {t("book_now")}
                                      </Link>
                                    )}
                                  </FormGroup>
                                ) : (
                                  <Button
                                    className="btn-edit-profile btn btn-primary"
                                    disabled={true}
                                  >
                                    {t("book_now")}
                                  </Button>
                                )}
                              </>
                              : (
                                ""
                              )}
                          </Form>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              {props.singleModel.data.model_ratings.length > 0 ? (
                <div className="new-review-sec">
                  <Row>
                    <Col md={12}>
                      <div className="new-review-header-sec">
                        <h3>{t("latest_reviews")}</h3>
                        {props.singleModel.data.user.total_ratings > 2 ? (
                          <Link to="#" onClick={showRating}>
                            <p>
                              {t("see_all")}{" "}
                              {props.singleModel.data.user.total_ratings}{" "}
                              {t("reviews")}
                            </p>
                          </Link>
                        ) : null}
                      </div>
                      <div className="new-review-box">
                        {props.singleModel.data.model_ratings.map(
                          (review, i) => (
                            <div className="new-review-card">
                              <div className="new-review-info">
                                <div className="new-review-img-sec">
                                  <span>
                                    {review.username.charAt(0).toUpperCase()}
                                  </span>
                                </div>
                                <div className="new-review-details">
                                  <h6>{review.username}</h6>
                                  <p>
                                    {t("about")} {review.created_formatted}
                                  </p>
                                </div>
                              </div>
                              <div className="new-review-rating-content-sec">
                                <div className="new-review-rating-card">
                                  {[...Array(5)].map((c, i) =>
                                    i <= review.rating ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="#FFF400"
                                        stroke="#FFF400"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        data-testid="icon-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="#fff"
                                        stroke="#fff"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        data-testid="icon-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                      </svg>
                                    )
                                  )}
                                </div>
                                <div className="new-review-desc-content">
                                  <p>{review.review}</p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </Container>
          </div>
          {props.singleModel.data.related_categories &&
            props.singleModel.data.related_categories.length > 0 ? (
            <div className="related-categories-sec" ref={reviewSec}>
              <Container>
                <Row>
                  <Col md={12}>
                    <h3>{t("related_categories")}</h3>
                    <div className="related-categories-box">
                      {props.singleModel.data.related_categories.map(
                        (category, i) => (
                          <Link to={`/browse/${category.category_unique_id}`}>
                            <div className="related-categories-card">
                              <p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#adaeb5"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                                >
                                  <circle cx="11" cy="11" r="8"></circle>
                                  <line
                                    x1="21"
                                    y1="21"
                                    x2="16.65"
                                    y2="16.65"
                                  ></line>
                                </svg>
                                <span>{category.name}</span>
                              </p>
                            </div>
                          </Link>
                        )
                      )}
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                <Col md={12}>
                  <div className="see-all-link-sec">
                    <Link to="#" className="see-all-link">
                      {t("see_all")}
                    </Link>
                  </div>
                </Col>
              </Row> */}
              </Container>
            </div>
          ) : null}
          <Container>
            <Row>
              <Col md={12} lg={6} xl={6}>
                <div className="single-filter-footer-sec new-single-model-footer-sec">
                  <div className="single-filter-about-sec">
                    <h4 className="title">
                      {t("what_is")} {configuration.get("configData.site_name")}
                      ?
                    </h4>
                    <Row>
                      <Col md={4} className="resp-mrg-btm-xs">
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src="assets/images/mobile-icon.svg"
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">{t("send_your_request")}</h4>
                        </div>
                      </Col>
                      <Col md={4} className="resp-mrg-btm-xs">
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src="assets/images/video-icon.svg"
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">{t("get_your_video")}</h4>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src="assets/images/thumsup.svg"
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">{t("make_their_year")}</h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={12} lg={6} xl={6}>
                <div className="single-filter-footer-sec new-single-model-footer-sec padding-zero">
                  <div className="single-filter-about-sec">
                    <h4 className="title-1">
                      {t("what_does_a_good_request_look_like")}
                    </h4>
                    <h5 className="sub-title">{t("tip_1")}</h5>
                    <p className="desc">{t("tip_1_content")}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
      <AllReviewModal
        allReviewModal={allReviewModal}
        setAllReviewModal={setAllReviewModal}
        handleCloseAllReviewModal={handleCloseAllReviewModal}
        modelRating={props.modelRating}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  singleModel: state.model.singleModel,
  modelPost: state.model.modelPost,
  modelSub: state.model.modelSub,
  modelRating: state.model.modelRating,
  modelSuggestion: state.model.modelSuggestion,
  cards: state.cards.cardDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SingleModelNew));
